html, body {
  height: 100%;
}

#root {
  min-height: 100%;
  background-color: #282c34;

}
.App {
  color: white;
  align-items: center;
  padding: 2em;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.App-link {
  color: #61dafb;
}
.Questions ul {
  columns: 3 36em;
}
.Questions li {
  list-style: none;
}